import axios from "axios";
import React, { useState, useEffect } from "react";
import "./footer.scss";

import { useWalletSelector } from "../../contexts/wallet-selector-context";

const NearPayComponent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [nearPayUrl, setNearPayUrl] = useState("");

  const { wallet } = useWalletSelector();

  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    setIsSignedIn(wallet?.walletSelector.isSignedIn());
  }, [wallet]);

  const request = {
    toCurrency: "NEAR",
    toWallet: wallet?.accountId,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(async () => {
  //   console.log(request);
  //   if (wallet?.accountId) {
  //     const sig = await axios.post(
  //       "https://qstn-nearpay.herokuapp.com/api/qstn/live/v1.0/registration",
  //       request
  //     );
  //     const baseURL = sig.data.data.sig;
  //     console.log("baseURL: ", baseURL);
  //     setNearPayUrl(baseURL);
  //   }
  // }, [wallet?.accountId]);

  return (
    <>
      <div className="container">
        <div className="row text-center pt-5">
          {isSignedIn ? (
            // eslint-disable-next-line jsx-a11y/iframe-has-title
            <iframe
              id="inlineFrameExample"
              width="416"
              height="548"
              src={"https://widget.munzen.io/"}
            ></iframe>
          ) : (
            <div className="warnning_text">Please Connect Wallet...</div>
          )}
        </div>
      </div>
    </>
  );
};

export default NearPayComponent;
