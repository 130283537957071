import Home01 from "./Home01";
import AboutUs from "./AboutUs";
import Collection from "./Collection";
import NFTs from "./NFTs";
import NearPay from "./NearPay";

const routes = [
  { path: "/", component: <Home01 /> },
  { path: "/mint", component: <AboutUs /> },
  { path: "/collection", component: <Collection /> },
  { path: "/mynft", component: <NFTs /> },
  { path: "/nearpay", component: <NearPay /> },
];

export default routes;
