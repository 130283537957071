import React, { useEffect, useState } from "react";
import "./allowlistbutton.scss";
import { useWalletSelector } from "../../contexts/wallet-selector-context";

import { NotificationManager } from "react-notifications";

const AllowlistButton = () => {
  const { wallet } = useWalletSelector();

  const [isSignedIn, setIsSignedIn] = useState(false);

  const [accountId, setAccountId] = useState("");

  async function getAll() {
    if (isSignedIn) {
      const response = await fetch(
        "https://qstn-admin-server.onrender.com/api/whitelist/get_all"
      ).then((response) => response.json());
      if (response.ok) {
        if (
          response.addrs.filter((item) => item.address == accountId).length ==
            1 &&
          response.addrs.filter((item) => item.address == accountId)[0].status
        ) {
          NotificationManager.info("You are in the whitelist", "Inform");
        } else {
          NotificationManager.error("You are not in the whitelist", "Inform");
        }
      } else {
        NotificationManager.error(
          "You are not registered in database or there are some errors",
          "error"
        );
      }
    } else {
      NotificationManager.warning("Please connect wallet first!", "warning");
    }
  }

  useEffect(() => {
    setIsSignedIn(wallet?.walletSelector.isSignedIn());
    setAccountId(wallet?.accountId);
  }, [wallet]);

  return (
    <button
      className="Abutton"
      onClick={() => getAll()}
      style={{
        fontFamily: "Poppins",
        color: "black",
      }}
    >
      Allowlist
    </button>
  );
};

export default AllowlistButton;
