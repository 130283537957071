import styled from "styled-components";

import Layout2Img from "../../assets/images/absolute-backs/layer-2.png";
import Layout1Img from "../../assets/images/absolute-backs/layer-1.png";
import AnnounImg from "../../assets/images/absolute-backs/announ.png";
import Announ1Img from "../../assets/images/absolute-backs/announ-1.png";
import Announ2Img from "../../assets/images/absolute-backs/announ-2.png";

const Announ = () => {
  return (
    <Wrapper backImg={Layout1Img}>
      <div className="containerss">
        <img className="announ-1" src={Announ1Img} alt=""></img>
        <img className="announ-2" src={Announ2Img} alt=""></img>
        <div className="label">
          <img src={AnnounImg}></img>
        </div>
        <AnnounContent backImg={Layout2Img}>
          <p>
            QSTN is an emerging Web3 platform looking to disrupt the advertising
            industry by giving individuals power and control over their data.
            Their team is building a web-based marketplace where users complete
            surveys from their favorite brands, creators and businesses in
            return for curated digital rewards.
          </p>
          <p>
            Prior to their application, the team is releasing the "QSTIEs", a
            generative NFT collection featuring their iconic QSTN mascot. These
            NFTs are metaverse wearables, allowing the owner to play as their
            character in metaverse worlds which accept GLB files
          </p>
        </AnnounContent>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #7153d9;
  background-image: url(${(p) => p.backImg});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 100%;
  padding-bottom: 750px;
  position: relative;

  .announ-1 {
    position: absolute;
    left: 50px;
    bottom: 100px;
  }

  .announ-2 {
    position: absolute;
    right: 60px;
    bottom: 100px;
  }
  .containerss {
    margin: auto;
    max-width: 1440px;
    padding-bottom: 750px;
    padding: 40px 0;
    .label {
      display: flex;
      justify-content: center;
      margin: 50px 0;
    }
  }
`;

const AnnounContent = styled.div`
  background-color: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 15px 0px #000000;
  border-radius: 20px;
  text-align: center;
  padding: 0 30px;
  width: 70%;
  margin: auto;
  background-image: url(${(p) => p.backImg});
  p {
    margin: 20px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #000000;
  }
`;
export default Announ;
