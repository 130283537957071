import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import Back1Img from "../../assets/images/absolute-backs/back1.png";
import Back2Img from "../../assets/images/absolute-backs/back2.png";

import ArrowLeftImg from "../../assets/images/icon/arrow-left.png";
import ArrowRightImg from "../../assets/images/icon/arrow-right.png";

import "./SliderOne.scss";

const SliderOne = () => {
  const images = [
    "https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%231.png",
    "https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%232.png",
    "https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%233.png",
    "https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%234.png",
    "https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%235.png",
    "https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%236.png",
    "https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%237.png",
  ];

  const [mySwiper, setMySwiper] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);

  const func_nextSlide = () => {
    mySwiper.slideNext();
  };
  const func_prevSlide = () => {
    mySwiper.slidePrev();
  };

  const [datatext] = useState({
    subtitle: "We are QSTIE NFTs",
    title: "Collect Next Generation NFTs Today",
    desc: "Introducing the first collection of 3D avatars on NEAR Protocol built for the metaverse",
  });

  return (
    <section className="wrapper">
      <div className="containers">
        <img className="back1" src={Back1Img} alt=""></img>
        <img className="back2" src={Back2Img} alt=""></img>
        <div className="labels">
          <p className="label-1">{datatext.subtitle}</p>
          <p className="label-2">
            Collect Next Generation <span>NFTs</span> Today
          </p>
          <p className="label-3">{datatext.desc}</p>
        </div>
        <Link
          to="/mint"
          className="btn-action style-1"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          Mint Now
        </Link>
        <img
          src={ArrowLeftImg}
          alt=""
          onClick={func_prevSlide}
          className="arrow-left"
        ></img>
        <img
          src={ArrowRightImg}
          alt=""
          onClick={func_nextSlide}
          className="arrow-right"
        ></img>
        <Swiper
          pagination={{
            clickable: true,
          }}
          slidesPerView={3}
          loop
          modules={[Autoplay, Navigation]}
          className="mySwiper"
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          onSwiper={(s) => {
            s.slideTo(swiperIndex, 0);
            setMySwiper(s);
          }}
          // breakpoints={{
          //   768: {
          //     slidesPerView: 1,
          //   },
          //   1200: {
          //     slidesPerView: 2,
          //   },
          //   1400: {
          //     slidesPerView: 3,
          //   },
          // }}
        >
          {images.map((item, index) => (
            <SwiperSlide className="swiper-slide" key={index}>
              <div className="swiper-item">
                <img src={item} alt="Monteno" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* <div className="container">
        <div className="row">
          <div className="col-xl-5 col-md-6 col-12">
            <div className="block-text pt-24">
              <h6 className="sub-title mb-6" data-aos="fade-up">
                {datatext.subtitle}
              </h6>
              <h2 className="title mb-26" data-aos="fade-up">
                {datatext.title}
              </h2>
              <p className="desc mb-43" data-aos="fade-up">
                {datatext.desc}
              </p>
              <Link
                to="/mint"
                className="btn-action style-1"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Mint Now
              </Link>
            </div>
          </div>
          <div className="col-xl-7 col-md-6 col-12">
            <div className="content-right d-flex">
              <Swiper
                modules={[Autoplay]}
                direction={"vertical"}
                spaceBetween={30}
                slidesPerView={3}
                loop
                autoplay={{
                  delay: 1,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                speed={2000}
              >
                <SwiperSlide>
                  <div className="item bg-1">
                    <img src={item.item1} alt="Monteno" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item bg-2">
                    <img src={item.item2} alt="Monteno" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item bg-1">
                    <img src={item.item1} alt="Monteno" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item bg-2">
                    <img src={item.item2} alt="Monteno" />
                  </div>
                </SwiperSlide>
              </Swiper>
              <Swiper
                modules={[Autoplay]}
                direction={"vertical"}
                spaceBetween={30}
                slidesPerView={3}
                loop
                autoplay={{
                  delay: 1,
                  reverseDirection: true,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                speed={2000}
              >
                <SwiperSlide>
                  <div className="item bg-2">
                    <img src={item.item3} alt="Monteno" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item bg-1">
                    <img src={item.item4} alt="Monteno" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item bg-2">
                    <img src={item.item5} alt="Monteno" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item bg-1">
                    <img src={item.item3} alt="Monteno" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item bg-2">
                    <img src={item.item4} alt="Monteno" />
                  </div>
                </SwiperSlide>
              </Swiper>
              <Swiper
                modules={[Autoplay]}
                direction={"vertical"}
                spaceBetween={30}
                slidesPerView={3}
                loop
                autoplay={{
                  delay: 1,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                speed={2000}
              >
                <SwiperSlide>
                  <div className="item bg-2">
                    <img src={item.item6} alt="Monteno" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item bg-1">
                    <img src={item.item7} alt="Monteno" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item bg-2">
                    <img src={item.item4} alt="Monteno" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item bg-1">
                    <img src={item.item6} alt="Monteno" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item bg-2">
                    <img src={item.item7} alt="Monteno" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className="row pt-24">
          <div className="col-xl-12 col-md-6 col-12 pt-24 text-center">
            <div className="block-text pt-24">
              <h2 className="title mb-26" data-aos="fade-up">
                GET YOUR HANDS ON A QSTIE
              </h2>
              <p className="desc mb-43" data-aos="fade-up">
                <div>
                  Each QSTIE is entirely unique, with each detail 3D designed in
                  Blender and then randomly generated on the NEAR Protocol
                  blockchain.
                </div>
                <div>
                  By owning a QSTIE, you not only join the NEAR NFT movement but
                  become a new piece in the data renaissance our platform aims
                  to inspire.
                </div>
                <div>
                  QSTIES will be available for purchase on our website when
                  minting goes live.
                </div>
                <div>
                  Upon purchase, a unique, blockchain-generated QSTIE will be
                  delivered to your NEAR wallet.
                </div>
                In order to demonstrate fairness, there will be no price tiers
                in the minting and all are initially priced at a flat rate of 10
                NEAR each.{" "}
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default SliderOne;
