import React from "react";
import styled from "styled-components";

import RoadMapImg1 from "../../assets/images/absolute-backs/roadmap-1.png";
import RoadMapImg2 from "../../assets/images/absolute-backs/roadmap-2.png";
import RoadMapImg3 from "../../assets/images/absolute-backs/roadmap-3.png";

const RoadMap = (props) => {
  const data = props.data;

  return (
    <section className="tf-section road-map">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="block-text center">
              <RoadMapTitle>
                <h1>ROADMAP</h1>
                <p>THE JOURNEY OF QSTN NFT</p>
              </RoadMapTitle>
              {/* <h3 data-aos="fade-up" data-aos-duration="1000">
                ROADMAP
              </h3>
              <p>THE JOURNEY OF QSTN NFT</p> */}
            </div>
          </div>
        </div>
      </div>

      <Container>
        <img className="roadmap-1" src={RoadMapImg1} alt=""></img>
        <img className="roadmap-2" src={RoadMapImg2} alt=""></img>
        <img className="roadmap-3" src={RoadMapImg3} alt=""></img>

        <div className="roadmap-container">
          {data.map((item, index) => (
            <RoadMapItem
              key={index}
              col={item.color}
              rot={item.rotate}
              wid={item.rotate}
            >
              <p className="title">
                {item.title}
                <RoadMapWay>
                  <svg width="10" height={item.height}>
                    <circle cx="5" cy="5" r="5" fill={"#FD562A"} />
                    <rect
                      width="2"
                      height={item.height - 30}
                      x="4"
                      y="15"
                      style={{
                        fill: "#404040",
                        marginTop: "10px",
                      }}
                    />
                    <circle
                      cx="5"
                      cy={item.height - 5}
                      r="5"
                      fill={"#FD562A"}
                    />
                  </svg>
                </RoadMapWay>
              </p>
              <div className="details">
                <p className="order">{item.time}</p>
                <p className="detail">{item.desc}</p>
              </div>
            </RoadMapItem>
          ))}
        </div>
      </Container>
      {/* <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="road-map__content s1">
              {data.map((data, index) => (
                <div
                  key={index}
                  className={`box-time ${data.class}`}
                  data-aos="fade-in"
                >
                  <span data-aos="zoom-in" data-aos-offset="300">
                    <i className="fa fa-check"></i>
                  </span>
                  <p className="fs-16 color-main mb-0">{data.time}</p>
                  <h5 className="title mb-20">{data.title}</h5>
                  <p className="fs-18">{data.desc} </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1440px;
  margin: auto;

  position: relative;

  .roadmap-1 {
    position: absolute;
    top: -100px;
    right: 20px;
  }

  .roadmap-2 {
    position: absolute;
    bottom: -200px;
    left: -10px;
  }

  .roadmap-3 {
    position: absolute;
    top: 700px;
    right: 200px;
  }

  .roadmap-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: min-content;
    gap: 100px;
  }
`;
const RoadMapItem = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  /* position: relative; */

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 100%;
    color: white;
    position: relative;
    text-align: right;
    white-space: nowrap;
  }

  .details {
    transform: rotate(${(p) => p.rot});
    background: #7153d9;
    border: 2px solid #000000;
    box-shadow: 0px 10px 0px #000000;
    border-radius: 13.7013px;
    width: 318px;
    min-width: 318px;
    position: relative;
    color: white;
    overflow: hidden;
    padding: 20px;
    .order {
      position: absolute;
      top: 0;
      left: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 26.0324px;
      line-height: 39px;
      text-transform: uppercase;
      background: ${(p) => p.col};
      border-radius: 0 0 20px 0;
      padding: 5px 20px;
    }
    .detail {
      margin-top: 70px;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 140%;
    }
  }
`;

const RoadMapWay = styled.div`
  position: absolute;
  top: 70px;
  right: 50px;
`;

const RoadMapTitle = styled.div`
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 46px;
    text-transform: uppercase;
    color: #7153d9;
  }
  p {
    color: #fd562a;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  margin-bottom: 70px;
`;

export default RoadMap;
