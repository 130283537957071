const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
    // namesub: [
    //     {
    //         id: 1,
    //         sub: 'Home 01',
    //         links: '/'
    //     },
    //     {
    //         id: 2,
    //         sub: 'Home 02',
    //         links: '/home-02'
    //     },
    //     {
    //         id: 3,
    //         sub: 'Home 03',
    //         links: '/home-03'
    //     },
    //     {
    //         id: 4,
    //         sub: 'Home One Page',
    //         links: '/home-one-page/'
    //     },
    // ]
  },
  {
    id: 2,
    name: "Mint",
    links: "/mint",
  },
  {
    id: 3,
    name: "Index",
    links: "/collection",
  },
  {
    id: 4,
    name: "My NFTs",
    links: "/mynft",
  },
  {
    id: 5,
    name: "Buy NEAR",
    links: "/nearpay",
  },
];

export default menus;
