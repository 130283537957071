import React, { useContext, useEffect, useState } from "react";
import { Wallet } from "../near-wallet";

export const WalletSelectorContext = React.createContext();

// export const CONTRACT_NAME = "nft_qstn.qstn_test.testnet";
// export const CONTRACT_NAME = "new.latest001.testnet";
export const CONTRACT_NAME = "contract.qstie.near";

export const WalletSelectorProvider = (props) => {
  const [wallet, setWallet] = useState();
  const [isSignedIn, setIsSignedIn] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const wallet_ = new Wallet({ createAccessKeyFor: CONTRACT_NAME });
    const isSignedIn_ = await wallet_.startUp();

    setWallet(wallet_);
    setIsSignedIn(isSignedIn_);
  }, []);

  return (
    <WalletSelectorContext.Provider value={{ wallet, isSignedIn }}>
      {props.children}
    </WalletSelectorContext.Provider>
  );
};

export const useWalletSelector = () => {
  const context = useContext(WalletSelectorContext);

  return context;
};
