import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import NearPayComponent from "../components/nearpay/NearPay";

const NearPay = () => {
  return (
    <div className="about">
      <Header />
      <NearPayComponent />
      <Footer />
    </div>
  );
};

export default NearPay;
