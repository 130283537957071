import React, { useState, useEffect, Component } from "react";
import styled from "styled-components";
import axios, { all } from "axios";
import Spinner from "./spinner/Spinner";

import NftBackImg from "../assets/images/absolute-backs/nft-back.png";
import { json } from "react-router-dom";
const rarityJson =
  "https://sapphire-magic-iguana-408.mypinata.cloud/ipfs/QmfLxPisg9JLsC4qFpmXaAv6BYP35E8dVJE6JbLjBixG5b?_gl=1*o5mx6j*_ga*Njc2NTA4NDkxLjE2NzYzNTAyMDc.*_ga_5RMPXG14TE*MTY3NjM1MDIwOC4xLjEuMTY3NjM1MDIyNi40Mi4wLjA.";

const Responsive = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [sortchange, setSortchange] = useState();
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(12);
  const [height, setHeight] = useState(0);
  const [rarityJsonData, setRarityJsonData] = useState([]);

  useEffect(async () => {
    let result = await axios.get(
      `https://sapphire-magic-iguana-408.mypinata.cloud/ipfs/QmcjKRw4PTCQfZbvdKnY7WWk1ZmL9Jgi42rgLDkBWbCjzd?_gl=1*1gu4qi9*_ga*Nzg5OTc4NDY2LjE2NzY4MzYwMzk.*_ga_5RMPXG14TE*MTY3Njg3MzE2MS41LjEuMTY3Njg3MzMxNC40NS4wLjA.`
    );
    setAllData(result.data);
  }, []);

  //  Get dummyData
  let dummyData = [];
  let data = [];
  const convert_number = function (input_value) {
    return Number(input_value.substring(0, input_value.length - 1));
  };

  useEffect(async () => {
    await axios.get(rarityJson).then((res) => {
      let tmp = [];
      for (let i = 0; i < res.data.data.length; i++)
        tmp.push(res.data.data[i].name);
      setRarityJsonData(tmp);
      // console.log("rarityData: ", tmp);
    });
  }, []);

  // useEffect(async () => {
  //   let temp = [];
  //   for (let i = 1; i <= 1000; i++) {
  //     let data = await axios.get(
  //       `https://bafybeifw4cfijrslq4vw7u47ihzry5jelrs2jqbwmcgpk3txl6t4guztja.ipfs.nftstorage.link/QSTN%23${i}.json`
  //     );
  //     let result = { name: data.data.name, attributes: data.data.attributes };
  //     temp.push(result);
  //   }
  //   console.log(temp);
  // }, []);

  // useEffect(() => {
  //   console.log(rarityJsonData);
  // }, [rarityJsonData]);

  useEffect(() => {
    let str =
      '[{"trait_type":"Character","value":"Question"},{"trait_type":"Body","value":"Blue"},{"trait_type":"Eyewear","value":"Summertime"},{"trait_type":"Hat","value":"Cap"},{"trait_type":"Hands","value":"White Fingerless"},{"trait_type":"Coin","value":"Verida"},{"trait_type":"Background","value":"Blue"}]';
    console.log('str.indexOf("trait_type"): ', str.includes("Question"));
  }, []);

  useEffect(async () => {
    setIsLoading(true);

    const rarityData = await axios.get(
      "https://bafybeifw4cfijrslq4vw7u47ihzry5jelrs2jqbwmcgpk3txl6t4guztja.ipfs.nftstorage.link/RARITIES.json"
    );

    // console.log("rarityData: ", rarityData);
    let sum = 0;
    Object.keys(rarityData.data["Background"]).map((item, index) => {
      sum +=
        100 -
        Number(
          rarityData.data["Background"][item].rarity.substring(
            0,
            rarityData.data["Background"][item].rarity.length - 1
          )
        );
    });

    if (props.filter === "") {
      data = [];
      dummyData = [];

      if (sortchange !== props.sort) {
        setStart(0);
        setEnd(12);
      }
      for (let i = start; i < end; i++) {
        let index = "";
        switch (props.sort) {
          case "sortA":
            index = i + 1;
            break;
          case "sortD":
            index = 1000 - i;
            break;
          case "rarityA":
            index = i + 1;
            break;
          case "rarityD":
            index = 1000 - i;
            break;
          default:
            break;
        }
        if (props.sort !== "rarityA" && props.sort !== "rarityD") {
          let jsonData = await axios.get(
            `https://bafybeifw4cfijrslq4vw7u47ihzry5jelrs2jqbwmcgpk3txl6t4guztja.ipfs.nftstorage.link/QSTN%23${index}.json`
          );
          let rarity = 1;
          jsonData.data.attributes.map((val, ind) => {
            rarity *=
              rarityData.data[val.trait_type][val.value].rarity.split("%")[0] *
              1;
          });
          let nftData = {
            previewImg: `https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%23${index}.png`,
            data: jsonData.data,
            id: index,
            rarity,
          };
          data.push(nftData);
          dummyData.push(nftData);
        } else {
          let jsonData = await axios.get(
            `https://bafybeifw4cfijrslq4vw7u47ihzry5jelrs2jqbwmcgpk3txl6t4guztja.ipfs.nftstorage.link/QSTN%23${rarityJsonData[
              index - 1
            ].replace("QSTN#", "")}.json`
          );
          let rarity = 1;
          jsonData.data.attributes.map((val, ind) => {
            rarity *=
              rarityData.data[val.trait_type][val.value].rarity.split("%")[0] *
              1;
          });
          let nftData = {
            previewImg: `https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%23${rarityJsonData[
              index - 1
            ].replace("QSTN#", "")}.png`,
            data: jsonData.data,
            id: index,
            rarity,
          };
          data.push(nftData);
          dummyData.push(nftData);
        }
      }
      let nftState = nfts;
      let dummy = [];

      if (start === 0) {
        dummy = data;
      } else {
        dummy = nftState.concat(data);
      }
      setNfts(dummy);
      setSortchange(props.sort);
    }
    setIsLoading(false);
  }, [start, props.filter, props.sort]);

  useEffect(async () => {
    setIsLoading(true);

    if (props.filter !== "") {
      const rarityData = await axios.get(
        "https://bafybeifw4cfijrslq4vw7u47ihzry5jelrs2jqbwmcgpk3txl6t4guztja.ipfs.nftstorage.link/RARITIES.json"
      );
      dummyData = [];

      let jsonData = [];
      for (let i = 0; i < allData.length; i++) {
        if (
          JSON.stringify(allData[i]).toLocaleLowerCase().includes(props.filter)
        )
          jsonData.push(allData[i]);
      }
      // jsonData = await axios.get(
      //   `https://bafybeifw4cfijrslq4vw7u47ihzry5jelrs2jqbwmcgpk3txl6t4guztja.ipfs.nftstorage.link/QSTN%23${props.filter}.json`
      // );
      console.log("jsondata: ", jsonData);
      let tmp = [];
      for (let i = 0; i < jsonData.length; i++) {
        jsonData[i].attributes.map((val, ind) => {
          val.rarity =
            (
              100 -
              convert_number(rarityData.data[val.trait_type][val.value].rarity)
            ).toFixed(2) + "%";
        });
        let nftData = {
          previewImg: `https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%23${jsonData[
            i
          ].name.replace("QSTN#", "")}.png`,
          data: jsonData[i],
          id: props.filter,
        };

        tmp.push(nftData);
        console.log("nfts: ", nftData);
      }
      setNfts(tmp);
      dummyData = tmp;
    }

    setIsLoading(false);
  }, [props.filter]);

  const loadMore = () => {
    setStart(nfts.length);
    setEnd(nfts.length + 4);
  };

  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="row justify-content-center">
          {nfts.map((nft, index) => (
            <div
              key={index}
              className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4"
            >
              <div
                className="nft__item m-0"
                style={{
                  minHeight: "100%",
                  backgroundImage: `url(${NftBackImg})`,
                  border: "1px solid #000000",
                  boxShadow: "0px 8.65949px 0px #000000",
                  borderRadius: "7px",
                }}
              >
                <div className="nft__item_wrap">
                  <span>
                    <img
                      onLoad={onImgLoad}
                      src={nft.previewImg}
                      className="lazy nft__item_preview"
                      alt=""
                    />
                  </span>
                </div>
                <div className="nft__item_info">
                  <NftName>
                    <h5>{nft.data.name}</h5>
                    <h5>rank:{rarityJsonData.indexOf(nft.data.name) + 1}</h5>
                  </NftName>
                  <div className="row">
                    {nft.data.attributes.map((val, index) => (
                      <div
                        className="col-md-6 m-0 p-1 character"
                        key={index}
                        style={{
                          width:
                            nft.data.attributes.length - 1 === index
                              ? "100%"
                              : undefined,
                          marginBottom:
                            nft.data.attributes.length - 1 === index
                              ? "50px !important"
                              : undefined,
                        }}
                      >
                        <NftInfoBox>
                          <span className="title">{val.trait_type} </span>
                          <p className="value">{val.value.toLowerCase()}</p>
                          <p className="rarity">{val.rarity}</p>
                        </NftInfoBox>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {nfts.length !== dummyData.length && (
            <div className="col-lg-12">
              <div className="spacer-single"></div>
              <span onClick={() => loadMore()} className="btn-main lead m-auto">
                Load More
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const NftName = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h5 {
    color: #7153d9;
    font-style: normal;
    font-weight: 900;
    font-size: 17px;
    line-height: 30px;
  }
`;

const NftInfoBox = styled.p`
  min-height: 100%;
  background: white;
  border-radius: 9px;
  position: relative;
  padding: 10px 5px;
  p {
    margin-bottom: 0 !important;
  }
  .title {
    color: black;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
  }
  .value {
    color: black;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
  .rarity {
    position: absolute;
    color: #fd562a;
    font-style: normal;
    font-weight: 700;
    font-size: 11.546px;
    line-height: 17px;
    top: 10px;
    right: 2px;
  }
`;

export default Responsive;
