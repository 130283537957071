import React, { useState, useEffect, Component } from "react";
import styled from "styled-components";
import axios from "axios";
import Spinner from "./spinner/Spinner";
import { useWalletSelector } from "../contexts/wallet-selector-context";
import { CONTRACT_NAME } from "../contexts/wallet-selector-context";

import NftBackImg from "../assets/images/absolute-backs/nft-back.png";

const IPFS = "QmUkcUvMob9qNc8pqMg23v4TjDVy16md1j5oSpwtzZ7odc";

const rarityJson = "https://sapphire-magic-iguana-408.mypinata.cloud/ipfs/QmfLxPisg9JLsC4qFpmXaAv6BYP35E8dVJE6JbLjBixG5b?_gl=1*o5mx6j*_ga*Njc2NTA4NDkxLjE2NzYzNTAyMDc.*_ga_5RMPXG14TE*MTY3NjM1MDIwOC4xLjEuMTY3NjM1MDIyNi40Mi4wLjA.";

async function download(url, name) {
  const a = document.createElement("a");
  a.href = await toDataURL(url);
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
function toDataURL(url) {
  return fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
}

const Responsive = (props) => {


  const [rarityData, setRarityData] = useState([]);

  useEffect(async () => {
    await axios.get(rarityJson).then(res => {
      let tmp = [];
      for (let i = 0; i < res.data.data.length; i++) tmp.push(res.data.data[i].name.replace('#', ''));
      setRarityData(tmp);
    });
  }, [])

  useEffect(() => {
    console.log(rarityData.indexOf('QSTN#988'));
  }, [rarityData])


  const { wallet } = useWalletSelector();

  const [isSignedIn, setIsSignedIn] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const [nfts, setNfts] = useState([]);
  const [sortchange, setSortchange] = useState();
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(12);
  const [height, setHeight] = useState(0);
  //  Get dummyData
  let dummyData = [];

  useEffect(() => {
    setIsSignedIn(wallet?.walletSelector.isSignedIn());
  }, [wallet]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (isSignedIn) {
      console.log(window.contract);

      setInitialized(true);
    }
  }, [isSignedIn]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!initialized) return;
    const accountId = wallet.accountId;

    let res = await wallet.viewMethod({
      contractId: CONTRACT_NAME,
      method: "nft_tokens_for_owner",
      args: {
        account_id: accountId,
        frin_index: "0",
        limit: parseInt(1000),
      },
    });
    setNfts(res);
    console.log('res: ', res);
    setIsLoading(false);
  }, [start, initialized, wallet]);

  const loadMore = () => {
    setStart(nfts.length);
    setEnd(nfts.length + 4);
  };

  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="row justify-content-center">
          {nfts.map((nft, index) => (
            <div
              key={index}
              className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4"
              // onClick={() => download(nft.metadata.media, nft.metadata.title)}
            >
              <div
                className="nft__item m-0"
                style={{
                  minHeight: "100%",
                  backgroundImage: `url(${NftBackImg})`,
                  backgroundSize: "cover",
                }}
              >
                <h3 className="rank" style={{
                  textAlign: 'center',
                  margin: 0,
                  padding: 0,
                  color: '#7153d9'
                }}>rank: {rarityData.indexOf(nft.metadata.title)+1}</h3>
                <div className="nft__item_wrap">
                  <span>
                    <img
                      onLoad={onImgLoad}
                      src={nft.metadata.media}
                      className="lazy nft__item_preview"
                      alt=""
                    />
                  </span>
                </div>
                <div
                  className="nft__item_info p-1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <NftName
                    style={{
                      paddingTop: "8px",
                    }}
                  >
                    <h4>{nft.metadata.title}</h4>
                  </NftName>
                  <a
                    href={
                      `https://sapphire-magic-iguana-408.mypinata.cloud/ipfs/${IPFS}/QSTN%23` +
                      nft.metadata.title.replace("QSTN", "") +
                      ".glb"
                    }
                    style={{
                      color: "black",
                      fontSize: "22px",
                    }}
                  >
                    Claim
                  </a>
                  {/* <div className="row">
                  </div> */}
                </div>
              </div>
            </div>
          ))}
          {nfts.length !== dummyData.length && (
            <div className="col-lg-12">
              <div className="spacer-single"></div>
              <span onClick={() => loadMore()} className="btn-main lead m-auto">
                Load More
              </span>
            </div>
          )}
          {!isSignedIn && (
            <h2 style={{ textAlign: "center", color: "#212529" }}>
              Please connect wallet first.
            </h2>
          )}
        </div>
      )}
    </>
  );
};

const NftName = styled.span`
  h4 {
    color: #7153d9;
    font-style: normal;
    font-weight: 900;
    font-size: 23px;
    line-height: 30px;
  }
`;

export default Responsive;
