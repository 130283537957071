import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import styled, { css } from "styled-components";

import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import Spinner from "../spinner/Spinner";
import orders from "../../assets/fake-data/orders";
import "reactjs-popup/dist/index.css";
import Modal from "react-bootstrap/Modal";

import { NotificationManager } from "react-notifications";

import { useWalletSelector } from "../../contexts/wallet-selector-context";
import { CONTRACT_NAME } from "../../contexts/wallet-selector-context";

import BN from "bn.js";

import NFTBackImg from "../../assets/images/absolute-backs/nft-back.png";
import ArrowLeftImg from "../../assets/images/icon/arrow-left.png";
import ArrowRightImg from "../../assets/images/icon/arrow-right.png";

const rarityJson =
  "https://sapphire-magic-iguana-408.mypinata.cloud/ipfs/QmfLxPisg9JLsC4qFpmXaAv6BYP35E8dVJE6JbLjBixG5b?_gl=1*o5mx6j*_ga*Njc2NTA4NDkxLjE2NzYzNTAyMDc.*_ga_5RMPXG14TE*MTY3NjM1MDIwOC4xLjEuMTY3NjM1MDIyNi40Mi4wLjA.";

const SliderThree = () => {
  const { wallet } = useWalletSelector();

  const [isSignedIn, setIsSignedIn] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [latestTokenId, setLatestTokenId] = useState("");
  const [latestMedia, setLatestMedia] = useState("");

  const params = new URLSearchParams(window.location.search);

  const [show, setShow] = useState(0);
  const [confirmShow, setConfirmShow] = useState(0);

  const [rarityData, setRarityData] = useState([]);
  const [confirmMint, setConfirmMint] = useState(false);

  useEffect(async () => {
    await axios.get(rarityJson).then((res) => {
      let tmp = [];
      for (let i = 0; i < res.data.data.length; i++)
        tmp.push(res.data.data[i].name);
      setRarityData(tmp);
    });
  }, []);

  useEffect(() => {
    console.log(rarityData.indexOf("QSTN#988"));
  }, [rarityData]);

  const handleClose = () => setShow(0);

  const [dataText, setDataText] = useState({
    subtitle: "We are QSTN NFT",
    title: "Minting is now LIVE!",
    desc: "0 of 1000 QSTNs are minted now.",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const images = [
    "879",
    "213",
    "469",
    "300",
    "869",
    "709",
    "12",
    "955",
    "600",
    "900",
  ];

  const [json, setJson] = useState({
    description: "A character for QSTN Collection",
    image: "[IMAGE_PATH]",
    name: "QSTN#781",
    attributes: [
      {
        trait_type: "Character",
        value: "Question",
      },
      {
        trait_type: "Body",
        value: "Striped Purple",
      },
      {
        trait_type: "Eyewear",
        value: "Snowboard",
      },
      {
        trait_type: "Hat",
        value: "Pink Bucket",
      },
      {
        trait_type: "Hands",
        value: "Cyan",
      },
      {
        trait_type: "Coin",
        value: "Golden",
      },
      {
        trait_type: "Background",
        value: "Cream",
      },
    ],
  });

  const mintNFT = async () => {
    const accountId = wallet.accountId;
    // let res = await wallet.viewMethod({
    //   contractId: CONTRACT_NAME,
    //   method: "nft_tokens_for_owner",
    //   args: {
    //     account_id: accountId,
    //     frin_index: "0",
    //     limit: parseInt(1000),
    //   },
    // });
    // if (res.length >= 20) {
    //   NotificationManager.warning(
    //     "You cannot mint more than 20 nfts.",
    //     "inform"
    //   );
    //   setIsLoading(false);
    //   return;
    // }
    const config_data = await wallet.viewMethod({
      contractId: CONTRACT_NAME,
      method: "get_config",
      args: {},
    });
    const i = config_data.total_supply;

    // let arr = [
    // 391, 400, 210, 247, 488, 547, 953, 670, 1, 149, 144, 262, 799, 905, 715,
    // 576, 170, 758, 232, 587, 791, 840, 445, 793, 939, 174, 466, 178, 870, 92,
    // 188, 212, 319, 976, 101, 156, 230, 427, 511, 80, 278, 792, 402, 142, 909,
    // 490, 111, 551, 254, 213, 509, 374, 645, 978, 964, 999, 836, 383, 54, 158,
    // 775, 618, 216, 678, 902, 77, 350, 543, 279, 486, 699, 573, 597, 267,
    // ]

    if (i === 1000) {
      NotificationManager.warning("There is no nfts to mint anymore", "inform");
      setIsLoading(false);
      return;
    }

    console.log(i, orders[i]);

    await wallet
      .callMethod({
        contractId: CONTRACT_NAME,
        method: "nft_mint",
        args: {
          token_id: `QSTN${orders[i]}`,
          metadata: {
            title: `QSTN${orders[i]}`,
            description: "QSTN token",
            media: `https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%23${orders[i]}.png`,
          },
          receiver_id: accountId,
        },
        gas: "300000000000000",
        deposit: new BN("8100000000000000000000000"),
      })
      .catch((err) => console.log(err));

    // await wallet
    //   .callMethod({
    //     contractId: CONTRACT_NAME,
    //     method: "nft_mint",
    //     args: {
    //       token_id: `QSTN${orders[i]}`,
    //       metadata: {
    //         title: `QSTN${orders[i]}`,
    //         description: "QSTN token",
    //         media: `https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%23${orders[i]}.png`,
    //       },
    //       receiver_id: accountId,
    //     },
    //     gas: "300000000000000",
    //     deposit: new BN("8100000000000000000000000"),
    //   })
    //   .catch((err) => console.log(err));
    setIsLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [nfts, setNfts] = useState([]);

  useEffect(async () => {
    let temp = [];
    for (let i = 0; i < images.length; i++) {
      const jsonUrl = `https://bafybeifw4cfijrslq4vw7u47ihzry5jelrs2jqbwmcgpk3txl6t4guztja.ipfs.nftstorage.link/QSTN%23${images[i]}.json`;
      const data = await axios.get(jsonUrl);
      // setCurrentImage(url);
      let img_url = data.data.name.replace("#", "%23");
      temp.push({ ...data.data, img_url });
    }
    setNfts(temp);
  }, []);
  // useEffect(async () => {
  //   setIsLoading(true);

  //   const intervalId = setInterval(async () => {
  //     const imgNum = getRandomInt(1, 10);
  //     // const url = require(`../../assets/images/about/${
  //     //   images[imgNum - 1]
  //     // }.png`);
  //     setGlobalImgNum(imgNum);
  //     const jsonUrl = `https://bafybeifw4cfijrslq4vw7u47ihzry5jelrs2jqbwmcgpk3txl6t4guztja.ipfs.nftstorage.link/QSTN%23${
  //       images[imgNum - 1]
  //     }.json`;
  //     const data = await axios.get(jsonUrl);
  //     // setCurrentImage(url);
  //     setJson({ ...data.data });
  //   }, 2000);

  //   setIsLoading(false);

  //   return () => clearInterval(intervalId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    setIsSignedIn(wallet?.walletSelector.isSignedIn());
  }, [wallet]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (wallet === undefined) return;
    const resp = await wallet.viewMethod({
      contractId: CONTRACT_NAME,
      method: "get_config",
      args: {},
    });

    if (params.get("transactionHashes") != null) {
      const accountId = wallet.accountId;
      const res = await wallet.viewMethod({
        contractId: CONTRACT_NAME,
        method: "nft_tokens_for_owner",
        args: {
          account_id: accountId,
          frin_index: "0",
          limit: parseInt(1000),
        },
      });

      setLatestTokenId(res[res.length - 1].token_id);
      setLatestMedia(res[res.length - 1].metadata.media);

      setShow(1);
    } else if (params.get("errorCode") != null) {
      setShow(2);
    }
    console.log("resp.total_supply: ", resp.total_supply);
    console.log(
      `${resp.total_supply} of ${resp.max_supply} QSTNs are minted now.`
    );
    setDataText({
      ...dataText,
      desc: `${resp.total_supply} of ${resp.max_supply} QSTNs are minted now.`,
    });
  }, [wallet]);

  const [mySwiper, setMySwiper] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);

  const func_nextSlide = () => {
    mySwiper.slideNext();
  };
  const func_prevSlide = () => {
    mySwiper.slidePrev();
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <section className="tf-section hero-slider" style={{ padding: 0 }}>
          <Modal show={show} onHide={handleClose}>
            {show === 1 ? (
              <Modal.Body className="modal-style">
                Mint was successful!
                <h5
                  style={{
                    opacity: "0.7",
                    marginTop: "10px",
                  }}
                >
                  {latestTokenId}
                </h5>
                <img
                  style={{
                    width: "100%",
                  }}
                  src={latestMedia}
                  alt=""
                ></img>
              </Modal.Body>
            ) : (
              <Modal.Body className="modal-style-failed">
                Mint was failed!
              </Modal.Body>
            )}
          </Modal>

          <Modal show={confirmShow} onHide={() => setConfirmShow(0)}>
            <Modal.Body className="modal-style">
              I understand and agree that minting this NFT forfeits my right to
              withdrawal. This means once you purchase an NFT, you have no right
              to a refund.
              <ConfirmMint>
                <input
                  type={"checkbox"}
                  id="confirm-mint"
                  value={confirmMint}
                  onChange={(e) => {
                    setConfirmMint(e.target.checked);
                  }}
                ></input>
                <label htmlFor="confirm-mint">I agree with this.</label>
              </ConfirmMint>
              <ModalButtons flag={confirmMint}>
                <button
                  className="mint"
                  onClick={async () => {
                    if (!confirmMint) return;
                    try {
                      const res = await axios.post(
                        "https://minters-server.onrender.com/api/create",
                        {
                          address: wallet.accountId,
                        }
                      );
                    } catch (err) {
                      console.log(err);
                    }
                    console.log("--------------------------------");
                    !isSignedIn ? wallet.signIn() : mintNFT();
                    setConfirmShow(0);
                  }}
                >
                  Mint
                </button>
                <button className="close" onClick={() => setConfirmShow(0)}>
                  Close
                </button>
              </ModalButtons>
            </Modal.Body>
          </Modal>
          {/* <div className="container">
            <div className="row">
              <div className="col-xl-5 col-md-12 col-12">
                <div className="block-text pt-24">
                  <h6 className="sub-title mb-6" data-aos="fade-up">
                    {dataText.subtitle}
                  </h6>
                  <h2 className="title mb-26" data-aos="fade-up">
                    {dataText.title}{" "}
                  </h2>
                  <p className="desc mb-43" data-aos="fade-up">
                    {dataText.desc}{" "}
                  </p>
                  <Link
                    to="/mint"
                    className="btn-action style-2"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    onClick={(e) => {
                      e.preventDefault();
                      !isSignedIn ? wallet.signIn() : mintNFT();
                    }}
                  >
                    {isSignedIn ? "Mint" : "Connect Wallet"}
                  </Link>
                </div>
              </div>
              <div className="col-xl-7 mt-5">
                <div className="nft__item m-0  flex-row">
                  <div
                    className="w-50"
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      src={
                        "https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%2312.png"
                      }
                      style={{ opacity: 0 }}
                    ></img>
                    {images.map((item, index) => (
                      <img
                        src={`https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/QSTN%23${item}.png`}
                        className="Mint_preview_image"
                        alt=""
                        style={{
                          opacity: index + 1 == globalImgNum ? 1 : 0,
                          position: "absolute",
                          marginButtom: "20px",
                          top: 0,
                          left: 0,
                        }}
                      />
                    ))}
                  </div>
                  <div className="nft__item_info  p-5">
                    <span>
                      <h4>{json.name}</h4>
                    </span>
                    {json.attributes.map((val, index) => (
                      <div key={index}>
                        <p className="m-0 text-black">
                          {val.trait_type}: {val.value}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <Wrapper>
            <Container>
              <div className="labels">
                <p className="label-1">WE ARE QSTIE NFTS</p>
                <p className="label-2">
                  Minting is now <span>live</span>
                </p>
                <p className="label-3">{dataText.desc}</p>
                {/* <p className="label-4">
                  I agree to the immediate provision of the contractual service
                  (i.e. receiving the NFT) and I have been informed that I
                  hereby lose the right of Withdrawal.*
                </p> */}
                <Link
                  to="/mint"
                  className="btn-action style-2"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmShow(1);
                  }}
                >
                  Mint now
                </Link>
              </div>

              <div className="nfts">
                <img
                  src={ArrowLeftImg}
                  alt=""
                  onClick={func_prevSlide}
                  className="arrow-left"
                ></img>
                <img
                  src={ArrowRightImg}
                  alt=""
                  onClick={func_nextSlide}
                  className="arrow-right"
                ></img>
                <Swiper
                  modules={[Autoplay]}
                  pagination={{
                    clickable: true,
                  }}
                  spaceBetween={30}
                  loop
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  className="mySwiper"
                  onSwiper={(s) => {
                    s.slideTo(swiperIndex, 0);
                    setMySwiper(s);
                  }}
                >
                  {nfts.map((item, index) => (
                    <SwiperSlide>
                      <NFTInfo bgImg={NFTBackImg}>
                        <img
                          src={`https://bafybeidjdmfup4i22iwnt7uenvwtr4gtxvgrbyh6bmjptm2tsc2ivd7bhi.ipfs.nftstorage.link/${item.img_url}.png`}
                          alt=""
                        />
                        <div className="nft-info">
                          <div className="name-rank">
                            <h1 className="token_id">{item.name}</h1>
                            <h3>rank: {rarityData.indexOf(item.name) + 1}</h3>
                          </div>

                          <div className="infos">
                            <div className="info-two">
                              <div>
                                <p className="p-1">Characher</p>
                                <p className="p-2">
                                  {item.attributes[0]["value"]}
                                </p>
                              </div>
                              <div>
                                <p className="p-1">Body</p>
                                <p className="p-2">
                                  {item.attributes[1]["value"]}
                                </p>
                              </div>
                            </div>

                            <div className="info-two">
                              <div>
                                <p className="p-1">Eyewear</p>
                                <p className="p-2">
                                  {item.attributes[2]["value"]}
                                </p>
                              </div>
                              <div>
                                <p className="p-1">Hat</p>
                                <p className="p-2">
                                  {item.attributes[3]["value"]}
                                </p>
                              </div>
                            </div>

                            <div className="info-two">
                              <div>
                                <p className="p-1">Hands</p>
                                <p className="p-2">
                                  {item.attributes[4]["value"]}
                                </p>
                              </div>
                              <div>
                                <p className="p-1">Coin</p>
                                <p className="p-2">
                                  {item.attributes[5]["value"]}
                                </p>
                              </div>
                            </div>

                            <div className="info-one">
                              <p className="p-1">Background</p>
                              <p className="p-2">
                                {item.attributes[6]["value"]}
                              </p>
                            </div>
                          </div>
                        </div>
                      </NFTInfo>
                    </SwiperSlide>
                  ))}
                </Swiper>

                {/* <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>Slide 1</SwiperSlide>
                  <SwiperSlide>Slide 2</SwiperSlide>
                  <SwiperSlide>Slide 3</SwiperSlide>
                  <SwiperSlide>Slide 4</SwiperSlide>
                  <SwiperSlide>Slide 5</SwiperSlide>
                  <SwiperSlide>Slide 6</SwiperSlide>
                  <SwiperSlide>Slide 7</SwiperSlide>
                  <SwiperSlide>Slide 8</SwiperSlide>
                  <SwiperSlide>Slide 9</SwiperSlide>
                </Swiper> */}
              </div>
            </Container>
          </Wrapper>
        </section>
      )}
    </>
  );
};

const Wrapper = styled.div`
  * {
    box-sizing: border-box;
  }
`;

const NFTInfo = styled.div`
  background: #c0c0ff;
  border: 2px solid #000000;
  box-shadow: 0px 15px 0px #000000;
  border-radius: 12.3836px;
  width: 100%;
  background-image: url(${(p) => p.bgImg});
  height: 533.16px;
  @media screen and (max-width: 1400px) {
    height: 400px;
  }
  @media screen and (max-width: 1300px) {
    height: 350px;
  }
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  margin: auto;
  img {
    border-radius: 11.5048px;
    height: 100%;
  }

  .nft-info {
    .name-rank {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .token_id {
        @media screen and (max-width: 1400px) {
          /* font-size: 20px; */
        }
      }
    }
    .info-one {
      background: #ffffff;
      border-radius: 16.2531px;
      text-align: center;
      padding: 10px 0 5px 0;
      p {
        margin: 0;
      }
      .p-1 {
        font-style: normal;
        font-weight: 500;
        font-size: 13.6271px;
        line-height: 20px;
        padding: 0 !important;
      }

      .p-2 {
        font-style: normal;
        font-weight: 600;
        font-size: 20.4407px;
        line-height: 31px;
        color: #000000;
        padding: 0 !important;
      }
    }
    .info-two {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 17px;
      div {
        background: #ffffff;
        border-radius: 16.2531px;
        text-align: center;
        padding: 10px 0 5px 0;
        margin-bottom: 25px;
        p {
          margin: 0;
          padding: 0 !important;
        }
        color: black;
        .p-1 {
          font-style: normal;
          font-weight: 500;
          font-size: 13.6271px;
          line-height: 20px;
          padding: 0 !important;
        }

        .p-2 {
          width: 225.7px;
          font-style: normal;
          font-weight: 600;
          font-size: 20.4407px;
          line-height: 31px;
          color: #000000;
          padding: 0 !important;
        }
      }
    }
  }
`;

const Container = styled.div`
  max-width: 1440px;
  margin: auto;

  .labels {
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
    width: 60%;
    .label-1 {
      color: #fd562a;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }
    .label-2 {
      color: #7153d9;
      font-style: normal;
      font-weight: 700;
      font-size: 94px;
      line-height: 1.1;
      letter-spacing: -5px;
      span {
        color: #fd562a;
      }
    }
    .label-3 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 140%;
      color: black;
    }

    .label-4 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #383838;
    }

    .btn-action {
      border: 1px solid black;
      padding: 10px 20px;
    }
  }

  .nfts {
    position: relative;
    .arrow-left {
      position: absolute;
      top: 50%;
      left: 50px;
      background: #fd562a;
      border: 2px solid #000000;
      box-shadow: 0px 4px 0px #000000;
      border-radius: 60px;
      padding: 5px 20px;
    }
    .arrow-right {
      position: absolute;
      top: 50%;
      right: 50px;
      background: #fd562a;
      border: 2px solid #000000;
      box-shadow: 0px 4px 0px #000000;
      border-radius: 60px;
      padding: 5px 20px;
    }
  }
`;

const ModalButtons = styled.div`
  width: 80%;
  margin: auto;
  button {
    margin: 20px;
    background: #fd562a;
    border: 2px solid #000000;
    box-shadow: 0px 6px 0px #000000;
    border-radius: 60px;
    color: white;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 37px;
    padding: 0px 20px;
  }
  .mint {
    background-color: ${(p) => (!p.flag ? "grey" : "#fd562a")};
  }
`;

const ConfirmMint = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  margin-left: 30px;
  color: #fd562a;
  font-size: 15px;
`;

export default SliderThree;
