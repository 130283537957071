import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Buffer } from "buffer";
import ScrollToTop from "./ScrollToTop";
import "./assets/style.scss";

import "@near-wallet-selector/modal-ui/styles.css";

global.Buffer = Buffer;

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
