const dataRoadMap = [
  {
    time: "Phase 0",
    title: "Presale mint",
    desc: "Users whose wallets have been whitelisted for the presale will be able to mint early during this day (max: 100)",
    class: "left",
    color: "#7660FF",
    rotate: "3.13deg",
    height: 300,
  },
  {
    time: "Phase 1",
    title: "Public mint",
    desc: "The general public will have chance to mint remaining 800 QSTIES (some reserved for marketing)",
    class: "right mt-223",
    color: "#9DDB98",
    rotate: "-3.13deg",
    height: 300,
  },
  {
    time: "Phase 2",
    title: "GLB integration",
    desc: "All holders can claim the GLB file for their 3D avatar to equip in the metaverse (note: they must accept GLB format)",
    class: "left mt--23",
    color: "#C2AE5A",
    rotate: "3.13deg",
    height: 300,
  },
  {
    time: "Phase 3",
    title: "QSTN DAO",
    desc: "We begin the formation of a DAO based around the NFTs and offer user voting and governance through ownership",
    class: "right mt-200",
    color: "#DA96C5",
    rotate: "-3.13deg",
    height: 120,
  },
];

export default dataRoadMap;
