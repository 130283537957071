import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./button.scss";
import { useWalletSelector } from "../../contexts/wallet-selector-context";

const ButtonOne = () => {
  const { wallet } = useWalletSelector();

  const [isSignedIn, setIsSignedIn] = useState(false);

  const [accountId, setAccountId] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (isSignedIn) {
      setAccountId(wallet.accountId);

      const data = {
        address: wallet.accountId,
      };

      const response = await fetch(
        "https://qstn-admin-server.onrender.com/api/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        }
      ).then((response) => response.json());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  useEffect(() => {
    setIsSignedIn(wallet?.walletSelector.isSignedIn());
  }, [wallet]);

  return (
    <Link
      onClick={(e) => {
        e.preventDefault();
        !isSignedIn ? wallet.signIn() : wallet.signOut();
      }}
      className="btn-action"
      style={{
        fontFamily: "Poppins",
      }}
    >
      {isSignedIn
        ? "CONNECTED: " +
          accountId.slice(0, 5) +
          "..." +
          accountId.slice(accountId.length - 4, accountId.length)
        : "Connect Wallet"}
    </Link>
  );
};

export default ButtonOne;
