import React, { useEffect, useState } from "react";
import styled from "styled-components";

import FaqImg from "../../assets/images/absolute-backs/faq.png";
import Faq1Img from "../../assets/images/absolute-backs/faq-1.png";
import Vector from "../../assets/images/icon/Vector.png";

const FAQ = (props) => {
  let data = props.data;
  let data2 = props.data2;
  let all_data = data.concat(data2);

  console.log(all_data);

  const [accordion, setAccordition] = useState(-1);

  return (
    <Wrapper>
      <Container>
        <img src={FaqImg} alt="" style={{ marginBottom: "100px" }}></img>
        <img src={Faq1Img} alt="" className="back1"></img>
        {all_data.map((item, index) => (
          <Accordion
            onClick={() => setAccordition(index)}
            flag={accordion}
            mine={index}
          >
            <h1>
              {item.title}
              <img src={Vector} alt="" />
            </h1>
            <p>{item.text}</p>
          </Accordion>
        ))}
      </Container>
    </Wrapper>

    // <section className="tf-section faq">
    //     <div className="container">
    //         <div className="row">
    //             <div className="col-12">
    //                 <div className="block-text center">
    //                     <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>FAQ</span></h1>
    //                     <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">FAQ</h5>
    //                     <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">Frequently Aksed <br /> Questions</h3>
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="row">
    //             <div className="col-md-6">
    //                 <div className="flat-accordion" data-aos="fade-up">
    //                     {
    //                         data.map((data,index) => (
    //                             <Accordion className="h5 toggle-title" key={index} title={data.title} show={data.show}>
    //                                     <p className="fs-18">{data.text}</p>
    //                                 </Accordion>
    //                         ))
    //                     }
    //                 </div>
    //             </div>
    //             <div className="col-md-6">
    //                 <div className="flat-accordion" data-aos="fade-up">
    //                     {
    //                         data2.map((data2,index) => (
    //                             <Accordion className="h5 toggle-title" key={index} title={data2.title} show={data2.show}>
    //                                     <p className="fs-18">{data2.text}</p>
    //                                 </Accordion>
    //                         ))
    //                     }
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </section>
  );
};

const Wrapper = styled.div`
  background-color: #7153d9;
`;

const Container = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 75px;

  position: relative;
  z-index: 0;
  .back1 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
`;

const Accordion = styled.div`
  z-index: 1;
  width: 90%;
  background-color: white;
  color: ${(p) => (p.flag === p.mine ? "white" : "black")};

  background: ${(p) => (p.flag === p.mine ? "#FD562A" : "#ffffff")};
  border: 2px solid #000000;
  box-shadow: -10px 10px 0px #000000;
  border-radius: 20px;
  padding: 20px;

  margin-bottom: 20px;

  h1 {
    color: ${(p) => (p.flag === p.mine ? "white" : "black")};
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    margin: 0;
    position: relative;
  }

  p {
    margin: 0;
    padding: 0;
    height: ${(p) => (p.flag === p.mine ? "auto" : 0)};
    transition: all 0.3s;
    overflow: hidden;
  }
  img {
    position: absolute;
    top: 35%;
    right: 50px;
    transition: all 0.3s;
    transform: ${(p) =>
      p.flag === p.mine ? "rotate(-90deg)" : "rotate(0deg)"};
  }
`;

export default FAQ;
